import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import Animation from "../../assets/img/Animation - 1741350897013.gif"

const StripeSuccess = () => {
    return (
        <div className="stripe-success-container">
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} md={8} lg={6} className="text-center">
                        {/* GIF Animation */}
                        <img src={Animation} alt="Verification Success Animation" className="success-gif" />

                        {/* Title */}
                        <h2 className="success-title">Identity Verification Completed</h2>

                        {/* Subtitle */}
                        <p className="success-subtitle">
                            Your submission has been successfully received. <br />
                            You may now return back to the app.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default StripeSuccess
