import React from "react";

// import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import playstore from "../../../src/assets/img/badge_googleplay.svg";
import appstore from "../../../src/assets/img/badge_appstore.svg";
import myploscreen from "../../../src/assets/img/footerBatch.png";
import CategoryList from "views/Component/CategoryList";
const PartySuppliesCategories = () => {
  return (
    <>
      <div className="margindiv-header">
        <div className="partysuplies-cateris">
          <Container>
            <div className="myplo-title-h2 text-center d-block">
              <h2 className="mb-3">
                <span className="shape"></span>Party Supplies by Occasion
              </h2>
              <p>Find Party Supplies, Costumes & Leftovers for Less—Give Extras a Second Life!</p>
            </div>
            <CategoryList role={"partySupply"} type={"all"}/>
          </Container>
        </div>
        <section className="free-app-store">
          <Container>
            <Row>
              <Col md="10" className="mx-auto">
                <Row className="align-items-end">
                  <Col md="4" className="d-md-block d-none">
                    <div className="myplo_footertopimg">
                      <img alt="" src={myploscreen} className="img-fluid" />
                    </div>
                  </Col>
                  <Col md="8 px-md-5">
                    <div className="mpplo_download_appcnt">
                      <h3>
                        GET THE <span className="free-app">FREE</span>{" "}
                        <span>APP</span> NOW!
                      </h3>
                      <div className="myplo_footerapps">
                        <div className="myplo_googlepay">
                          <img alt="" src={playstore} />
                        </div>
                        <div className="myplo_googlepay">
                          <img src={appstore} alt="" />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};

export default PartySuppliesCategories;
