/* eslint-disable */

// const API_URL =  "http://192.168.1.13:5000/"
// const FRONT_URL =  "http://192.168.1.11:3000/"

const API_URL = "https://myplo.itworkshop.in/"
const FRONT_URL = "https://myplo.itworkshop.in/"

const CATEGORIES = [];
const themesetting = {};
export default { API_URL, CATEGORIES, themesetting, FRONT_URL };