import React from 'react'
import Footer from 'views/front/inc/Footer';
import Header from 'views/front/inc/Header';
import AOS from "aos";
import "aos/dist/aos.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "./App.css";
import "./Responsive.css";
import { useEffect } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import { APICALL } from 'helper/api/api';
import { useState } from 'react';
const Front = ({ cmp, header }) => {
    const Component = cmp;
    const location = useLocation()
    const path = location.pathname.startsWith("/") ? location.pathname.slice(1): location.pathname;
    const lastSegment = path.split('/').pop();
    const firstSegment = path.split("/")[0];


    const page = useLocation()?.pathname.replace(/^\//, "");
    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState(null);
    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: true,
        });
        AOS.refresh();
    }, []);

    useEffect(() => {
        getPageContentFun();
    }, [page]);

    const getPageContentFun = async () => {
        const pageName = page || "home"
        setLoading(true);
        try {
            const res = await APICALL("admin/getAllPageMeta", "post", { pageUrl: pageName });
            if (res?.status) {
                setPageData(res?.data);
                setLoading(false);
            }else{
                setPageData(null);
            }
        } catch (error) {
            setLoading(false);
            setPageData(null);
        }
    };


    console.log("firstSegment",firstSegment)
    console.log("pageData",pageData)

    useEffect(() => {
        if(firstSegment != "product-listing" || firstSegment != "vendor-listing"){
            if (pageData) {
              document.title = pageData?.metaTitle || "MyPlo";
        
              const descriptionMetaTag = document.querySelector("meta[name='description']");
              if (descriptionMetaTag) {
                descriptionMetaTag.setAttribute("content", pageData?.metaDesc || "MyPlo");
              } else {
                const metaTag = document.createElement("meta");
                metaTag.name = "description";
                metaTag.content = pageData?.metaDesc || "MyPlo";
                document.head.appendChild(metaTag);
              }
        
              const keywordsMetaTag = document.querySelector("meta[name='keywords']");
              if (keywordsMetaTag) {
                keywordsMetaTag.setAttribute("content", pageData?.metaDesc || "default, keywords");
              } else {
                const metaTag = document.createElement("meta");
                metaTag.name = "keywords";
                metaTag.content = pageData?.metaDesc || "default, keywords";
                document.head.appendChild(metaTag);
              }
        
              // ✅ Add or Update Canonical Link
              let canonicalLink = document.querySelector("link[rel='canonical']");
              if (canonicalLink) {
                canonicalLink.setAttribute("href", window.location.href);
              } else {
                canonicalLink = document.createElement("link");
                canonicalLink.rel = "canonical";
                canonicalLink.href = window.location.href;
                document.head.appendChild(canonicalLink);
              }
            }else{
              document.title = "MyPlo";
            }
        }
      }, [pageData, page]);

    return (
        <>
            <Header />
            <Component />
            <Footer />
            <Outlet />
        </>
    )
}

export default Front