import React, { useState } from "react"

import img1 from "../../../src/assets/img/products1.png"
import ad from "../../../src/assets/img/ad.png"
import chaticon from "../../../src/assets/img/chat_icon.png"
import noMessageImg from "../../../src/assets/img/no-conversation.jpg"

import { Link, useLocation, useNavigate } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import { useEffect } from "react"
import { auth, defaultUserIMG, imgBaseURL, socketURL, timeAgo, toastifyError } from "helper/Utility"
import { io } from "socket.io-client"
import { APICALL } from "helper/api/api"
import FrontNoData from "views/Component/FrontNoData"
import { useRef } from "react"
import FrontPageLoader from "helper/my-com/FrontPageLoader"

const Chat = () => {
    const navigate = useNavigate()
    const locationData = useLocation()
    const productDetails = locationData?.state || null
    const [userList, setUserList] = useState([])
    const [activeChat, setActiveChat] = useState(null)
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState({
        message: false
    })
    const [pageLoader, setPageLoader] = useState(false)

    const [inputMessage, setInputMessage] = useState("")
    const [messages, setMessages] = useState([])
    const [socket, setSocket] = useState(null)
    const chatBodyRef = useRef(null)

    useEffect(() => {
        getChatUserList(true)
        if (productDetails) {
            setCount(count + 1)
            setActiveChat({
                ...activeChat,
                product_id: productDetails?._id,
                product_title: productDetails?.title,
                receiver_id: productDetails?.userId?._id,
                receiver_name: productDetails?.userId?.fullName,
                sender_id: auth("user")?.id
            })
            getMessageChatList(productDetails?.room?._id)
        }
    }, [productDetails])

    useEffect(() => {
        if (socket) {
            socket.on("chat-list-updated", (data) => {
                console.log("chat-list-updated", data)
                getChatUserList()
            })
            return () => {
                socket.off("chat-list-updated")
            }
        }
    }, [socket])

    const [newMessagesCount, setNewMessagesCount] = useState(0)
    const [isAtBottom, setIsAtBottom] = useState(true)

    const scrollToBottom = () => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight
            setNewMessagesCount(0)
            setIsAtBottom(true)
        }
    }

    const handleScroll = () => {
        if (chatBodyRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = chatBodyRef.current
            setIsAtBottom(scrollTop + clientHeight >= scrollHeight - 10)
        } else {
            console.error("chatBodyRef.current is null or undefined")
        }
    }

    useEffect(() => {
        if (isAtBottom) {
            scrollToBottom()
        } else {
            setNewMessagesCount((prev) => prev + 1)
        }
    }, [messages])

    useEffect(() => {
        if (!chatBodyRef.current) return
        const chatBody = chatBodyRef.current

        chatBody.addEventListener("scroll", handleScroll)
        return () => chatBody.removeEventListener("scroll", handleScroll)
    }, [chatBodyRef.current])

    const getChatUserList = async (load) => {
        if (load) {
            setPageLoader(true)
        }
        try {
            const res = await APICALL("user/getUserChatList", "post", {})
            if (res?.status) {
                setUserList(res?.data)
            } else {
                setUserList([])
            }
        } catch (error) {
            console.log(error)
        } finally {
            setPageLoader(false)
        }
    }

    const getMessageChatList = async (roomId) => {
        try {
            setLoading({ ...loading, message: true })
            const res = await APICALL("user/getMessagesByRoomId", "post", { roomId })
            if (res?.status) {
                setMessages(res?.data)
            } else {
                setMessages([])
            }
        } catch (error) {
            console.log(error)
            setMessages([])
        } finally {
            setLoading({ ...loading, message: false })
        }
    }

    const handleChatUserClick = (user) => {
        setCount(count + 1)
        setActiveChat({
            ...activeChat,
            product_id: user?.productId?._id,
            product_title: user?.productId?.title,
            receiver_id: user?.opponent?._id,
            receiver_name: user?.opponent?.fullName,
            sender_id: auth("user")?.id,
            roomId: user?._id
        })
        getMessageChatList(user?._id)
    }

    // Initialize the socket connection
    useEffect(() => {
        if (count >= 0) {
            const newSocket = io(socketURL(), {
                reconnection: true,
                reconnectionAttempts: 20,
                reconnectionDelay: 3000
            })

            setSocket(newSocket)
            console.log("Socket initialized")

            return () => {
                newSocket.disconnect()
                console.log("Socket disconnected")
            }
        }
    }, [count])

    // Handle socket events
    useEffect(() => {
        if (socket && count) {
            const joinData = {
                productId: activeChat?.product_id,
                sender: auth("user")?.id,
                receiver: activeChat?.receiver_id
            }

            // console.log("Joining room with data:", joinData)
            socket.emit("join", joinData)

            socket.on("user-joined", (data) => {
                // console.log("User joined response:", data)

                setActiveChat({
                    ...activeChat,
                    product_id: activeChat?.product_id,
                    product_title: activeChat?.product_title,
                    receiver_id: activeChat?.receiver_id,
                    receiver_name: activeChat?.receiver_name,
                    sender_id: auth("user")?.id,
                    roomId: data?.roomId
                })
            })

            socket.on("receive-message", (message) => {
                console.log("New message received:", message)
                setMessages((prevMessages) => [...prevMessages, message])
            })

            socket.on("error", (error) => {
                console.error("Socket error:", error.message)
            })
        }
    }, [socket, count])

    const handleMessageSend = (e) => {
        e.preventDefault()
        if (inputMessage.trim()) {
            const messageData = {
                roomId: activeChat.roomId,
                sender: activeChat.sender_id,
                receiver: activeChat.receiver_id,
                content: inputMessage
            }
            console.log("messageData", messageData)
            socket.emit("send-message", messageData)
            // setMessages((prevMessages) => [...prevMessages, { sender: messageData.sender, content: messageData.content, timestamp: new Date().toISOString() }])
            setInputMessage("")
        }
    }

    const handleBackClick = () => {
        navigate(-1)
    }

    const handleQuickReply = (message) => {
        setInputMessage(message)
    }
    const handleBackClickchat = () => {
        setActiveChat(false)
    }
    return (
        <>
            <div className="margindiv-header">
                <div className="myplo_chat_page">
                    <div className="container">
                        <Row>
                            <Col lg="11" className="mx-auto">
                                <div class="con_chat_title">
                                    <Link to={"#"} onClick={() => handleBackClick()}>
                                        <i className="fa fa-arrow-left"></i>
                                        <h1>Chats</h1>
                                    </Link>
                                </div>
                                {pageLoader ? (
                                    <FrontPageLoader />
                                ) : userList?.length > 0 || productDetails ? (
                                    <div className="chat_flex_con">
                                        {/* User Listing */}
                                        <div className={`chat_flex_con_left ${activeChat ? "active" : ""}`}>
                                            <nav className="nn_chatltctn">
                                                {/* <div className="adminadd">
                            <img src={ad} className="img-fluid" alt="Admin" />
                          </div> */}
                                                <div className="nn_chatctn">
                                                    {userList?.map((user, i) => (
                                                        <div className={`rtlechatuimg_flex ${activeChat?.roomId == user?._id ? "active_chats" : ""}`} onClick={() => handleChatUserClick(user)}>
                                                            <div className="nn_chat_proctn">
                                                                <img src={user?.opponent?.avatar ? imgBaseURL() + user?.opponent?.avatar : defaultUserIMG} alt="User" />
                                                                <div className="chat_titles">
                                                                    <h2>{user?.opponent?.fullName}</h2>
                                                                    <div className="nn_buying_color">{user?.productId?.title}</div>
                                                                    <span style={{ fontSize: "14px" }}>{timeAgo(user?.lastMessageAt)}</span>
                                                                </div>
                                                            </div>
                                                            {user?.unreadCount > 0 && (
                                                                <div className="time-date_chat">
                                                                    <h6>{user?.unreadCount}</h6>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </nav>
                                        </div>

                                        {activeChat ? (
                                            <div className={`chat_flex_con_right ${activeChat ? "active" : ""}`}>
                                                {/* Header */}
                                                <div className="chatrtprofile_head">
                                                    <div className="backarrow_chat">
                                                        <button type="button" onClick={handleBackClickchat}>
                                                            <svg viewBox="0 0 24 24" width="24" height="24" className="sc-jTzLTM fznnpf">
                                                                <path d="M7.513 13.353l3.73 3.863a1.403 1.403 0 0 1-2.016 1.948l-6.082-6.298a1.39 1.39 0 0 1-.393-.998c.006-.359.149-.715.428-.985l6.298-6.082a1.402 1.402 0 0 1 1.948 2.017L7.562 10.55l12.309.215a1.402 1.402 0 1 1-.048 2.804l-12.31-.215z"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    <div className="nn_profile_">
                                                        <h3 className="nn_chatnm">{activeChat?.receiver_name}</h3>
                                                        <div className="nn_selling_color">{activeChat?.product_title}</div>
                                                    </div>
                                                </div>

                                                {/* Message Body */}
                                                <div className="chatrt_msg_body" ref={chatBodyRef}>
                                                    {!loading?.message ? (
                                                        <>
                                                            {messages?.map((item, i) => (
                                                                <div className={`nn_receivepart ${activeChat.sender_id == item?.sender ? "" : "left"}`}>
                                                                    <div className={`nn_leftpart `}>
                                                                        <div className="nortltrans">{item?.content}</div>
                                                                        <span className="dateseen">{timeAgo(item?.createdAt || item?.timestamp)}</span>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="text-center d-flex align-items-center justify-content-center">
                                                                <h6>Loading...</h6>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>

                                                {/* Bottom */}
                                                <div className="chatrt_msg_footer">
                                                    <div className="text-center">
                                                        {!isAtBottom && newMessagesCount > 0 && (
                                                            <button className="new-messages-btn" onClick={scrollToBottom}>
                                                                {newMessagesCount} New Message{newMessagesCount > 1 ? "s" : ""}
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div className="nn_chatbtmain">
                                                        <div className="nn_cus_reschatbtctn nn_chatbtctn">
                                                            <button className="nn_chatbtn" onClick={() => handleQuickReply(`Hii ${activeChat?.receiver_name} `)}>
                                                                Hii {activeChat?.receiver_name}
                                                            </button>
                                                            <button className="nn_chatbtn" onClick={() => handleQuickReply("You’re welcome!")}>
                                                                You’re welcome!
                                                            </button>
                                                            <button className="nn_chatbtn" onClick={() => handleQuickReply("No problem!")}>
                                                                No problem!
                                                            </button>
                                                            <button className="nn_chatbtn" onClick={() => handleQuickReply("OK, Thanks!")}>
                                                                OK, Thanks!
                                                            </button>
                                                            <button className="nn_chatbtn" onClick={() => handleQuickReply(`Thank You ${activeChat?.receiver_name} `)}>
                                                                Thank You {activeChat?.receiver_name}
                                                            </button>
                                                        </div>

                                                        <form onSubmit={handleMessageSend}>
                                                            <div className="nn_chatinput">
                                                                <textarea placeholder="Type a message" spellCheck="false" value={inputMessage} onChange={(e) => setInputMessage(e.target.value)}></textarea>

                                                                <div className="nn_chatsendbtn">
                                                                    <button type="submit" className="boredrradus">
                                                                        <img src={chaticon} alt="Chat Icon" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <div className={`chat_flex_con_right  ${activeChat ? "active" : ""}`}>
                                                    <div className="chatrtprofile_head justify-content-center">
                                                        <h5 className="text-center">Tap a conversation to start chatting</h5>
                                                    </div>
                                                    <div className="myplo_notFound">
                                                        <img src={noMessageImg} alt="empty" />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <>
                                        <FrontNoData />
                                    </>
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Chat
