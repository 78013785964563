import {
    Route,
    Routes,
} from "react-router-dom";
import "react-sliding-pane/dist/react-sliding-pane.css";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Admin from "./Admin";
import Front from "./Front";
import Home from "views/front/Home";
import Blog from "views/front/Blog";
import BlogDetails from "views/front/BlogDetails";
import ContactUs from "views/front/ContactUs";
import StripeSuccess from "views/front/StripeSuccess";
import VendorServicesCategories from "views/front/VendorServicesCategories";
import PartySuppliesCategories from "views/front/PartySuppliesCategories";
import ScrollToTop from "helper/my-com/ScrollToTop";
import { LoadScript } from '@react-google-maps/api';
const libraries = ['places'];
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Chat from "views/front/Chat";
import EditProfile from "views/front/EditProfile";
import ProductListing from "views/front/ProductListing";
import ProductDetails from "views/front/ProductDetails";
import OrderView from "views/front/OrderView";
import SellerDetails from "views/front/SellerDetails";
import OrderSuccess from "views/front/OrderSuccess";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StaticPages from "views/front/StaticPages";
import { useFrontDataContext } from "helper/context/FrontContextProvider";
import { useEffect } from "react";
import FrontPageLoader from "helper/my-com/FrontPageLoader";
import VerifyEmail from "views/front/return-page/VerifyEmail";
import VendorServiceListing from "views/front/VendorServiceListing";
import ServicesDetails from "views/front/ServicesDetails";
import { STRIPE_P_KEY } from "helper/Constant";
const App = () => {
    const { getGeneralSettingFun, generalSetting } = useFrontDataContext()
    useEffect(() => {
        getGeneralSettingFun()
    }, [])

    if (!generalSetting) {
        return <><FrontPageLoader /></>;
    }
    const stripePromise = loadStripe(STRIPE_P_KEY);
    return (
        <>
            <ScrollToTop />
            <LoadScript googleMapsApiKey={generalSetting?.googleApiKey} libraries={libraries} loadingElement={<div style={{ display: "none" }} />}>
                <Elements stripe={stripePromise}>
                    <Routes>
                        <Route path="/" element={<Front cmp={Home} />} />
                        <Route path="/blog" element={<Front cmp={Blog} />} />
                        <Route path="/blog-details/:id" element={<Front cmp={BlogDetails} />} />
                        <Route path="/contact" element={<Front cmp={ContactUs} />} />
                        <Route path="/stripe-success" element={<StripeSuccess />} />

                        <Route path="/categories" element={<Front cmp={PartySuppliesCategories} />} />
                        <Route path="/chat/conversation" element={<Front cmp={Chat} />} />
                        <Route path="/profile/:id" element={<Front cmp={EditProfile} />} />
                        <Route path="/product-listing" element={<Front cmp={ProductListing} />} />
                        <Route path="/product-listing/:id" element={<Front cmp={ProductListing} />} />
                        <Route path="/product-details/:id" element={<Front cmp={ProductDetails} />} />
                        <Route path="/order-view" element={<Front cmp={OrderView} />} />
                        <Route path="/seller-details" element={<Front cmp={SellerDetails} />} />
                        <Route path="/order-success" element={<Front cmp={OrderSuccess} />} />

                        <Route path="/pages/about-us" element={<Front cmp={StaticPages} />} />
                        <Route path="/pages/privacy-policy" element={<Front cmp={StaticPages} />} />
                        <Route path="/pages/terms-and-conditions" element={<Front cmp={StaticPages} />} />
                        <Route path="/pages/safety-tips" element={<Front cmp={StaticPages} />} />

                        <Route path="/verify-email/:token" element={<VerifyEmail />} />

                        <Route path="/vendor" element={<Front cmp={VendorServicesCategories} />} />
                        <Route path="/vendor-listing/:id" element={<Front cmp={VendorServiceListing} />} />

                        <Route path="/services-details/:id/:role" element={<Front cmp={ProductDetails} />} />

                        <Route path={`/admin/*`} element={<Admin />} />
                    </Routes>

                </Elements>
            </LoadScript>
            <ToastContainer />

        </>
    );
};

export default App;
